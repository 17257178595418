"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDefualtOrganizationAdmin = exports.instanceOfOrganizationAdmin = exports.instanceOfOrganization = void 0;
;
const getDefualtOrganizationAdmin = () => {
    return {
        Id: 0,
        OrgName: "",
        Verified: false,
        MainEmail: "",
    };
};
exports.getDefualtOrganizationAdmin = getDefualtOrganizationAdmin;
const instanceOfOrganizationAdmin = (object) => {
    return ('Id' in object &&
        'Verified' in object &&
        'OrgName' in object &&
        'MainEmail' in object);
};
exports.instanceOfOrganizationAdmin = instanceOfOrganizationAdmin;
const instanceOfOrganization = (object) => {
    return ('Id' in object &&
        'Uuid' in object &&
        'OrgName' in object &&
        'OrgAvatarUrl' in object &&
        'OrgOwnerId' in object &&
        'FoundedDate' in object &&
        'Settings' in object &&
        'Verified' in object &&
        'OrgImgId' in object &&
        'OrgVideoId' in object &&
        'AboutUs' in object &&
        'Website' in object);
};
exports.instanceOfOrganization = instanceOfOrganization;
