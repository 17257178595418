import React, { useContext, useEffect, useState } from "react";
import { OrganizationAdmin, instanceOfOrganizationAdmin } from "shared-models/build/organizations";
import { FirebaseAuthContext } from "../../context/firebase-auth";
import { sendPostRequest, toastError, toastSuccess } from "../../util/util";

const TourOperators = () => {
    const [operators, setOperators] = useState<OrganizationAdmin[]>([]);
    const user = useContext(FirebaseAuthContext);
    useEffect(() => {
        getOperators();
    }, [user])
    const getOperators = async () => {
        if (user === null) return;
        const [resp, err] = await sendPostRequest(user, "/organization/get-operators-for-admin", {}, "", "Failed to get operators.");
        if (err !== null) {
            console.error(resp, err);
            return;
        }
        setOperators(resp);
    }
    const verifyOperator = async (index : number) => {
        if (user == null) return;
        const op = operators[index];
        const verified = op.Verified;
        const [resp, err] = await sendPostRequest(user, "/organization/verify-operator", op, "", "Failed to verify operator.");
        if (err !== null) {
            console.error(resp, err);
            return;
        }
        toastSuccess(`Set verification for ${op.OrgName}`);
        let tempOps = operators;
        op.Verified = !verified;
        tempOps = [...operators.slice(0, index), op, ...operators.slice(index+1)];
        setOperators(tempOps);
    }
    return (
        <div>
            <h3 className="tw-font-bold tw-text-xl tw-mb-4">Operators</h3>
            {
                operators.map((val, index) => {
                    if (!instanceOfOrganizationAdmin(val)) return null;
                    return (
                        <div className="tw-grid tw-grid-cols-5 tw-gap-4 tw-pb-4 tw-pt-8 tw-border-b-2" key={val.Id}>
                            <div>
                                <a href={`/operator/profile/${val.Id}`} className="tw-text-[blue]">{val.Id}</a>
                            </div>
                            <div>{val.OrgName}</div>
                            <div>{val.MainEmail}</div>
                            <div>{val.Verified ? "verified" : "evaluate"}</div>
                            <div><button className="tw-red-button" onClick={() => verifyOperator(index)}>{val.Verified ? "Un-verify" : "Verify"}</button></div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default TourOperators;